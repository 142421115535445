body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'DIN-Regular';
    src: local('DIN-Regular'), url(./assets/fonts/DIN-bold/DIN-Regular.otf) format('truetype');
}

* {
    margin: 0;
    box-sizing: border-box;
    font-family: DIN-Regular;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
  width: 7px;
  margin-left: 10px;
}

::-webkit-scrollbar-thumb {
  background: #278858;
  border-radius: 10px;
}
